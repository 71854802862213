<template>
  <v-row id="monthSelectorStats">
    <v-spacer></v-spacer>
    <v-col
      cols="2"
      sm="6"
      md="5"
      class="mr-md-0 py-0"
      :style="
        $vuetify.breakpoint.xsOnly
          ? 'display:flex; justify-content: center; align-items: center'
          : 'margin-right: -2%; text-align: right;'
      "
    >
      <v-btn-toggle v-model="btn" style="margin-top: 16px" mandatory>
        <v-menu
          v-model="menu.day"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              value="day"
              active-class="selected"
              outlined
              height="30"
              >{{ $t("day") }}</v-btn
            >
          </template>
          <v-date-picker
            first-day-of-week="1"
            no-title
            v-model="day"
            @input="menu.day = false"
            color="primary"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="menu.week"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              value="week"
              active-class="selected"
              outlined
              height="30"
              >{{ $t("week") }}</v-btn
            >
          </template>
          <v-date-picker
            first-day-of-week="1"
            no-title
            v-model="week"
            @input="menu.week = false"
            color="primary"
            range
            class="weekSelector"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menu.month"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              value="month"
              active-class="selected"
              outlined
              height="30"
            >
              {{ $t("month") }}
            </v-btn>
          </template>
          <v-date-picker
            first-day-of-week="1"
            no-title
            v-model="month"
            @input="menu.month = false"
            type="month"
            color="primary"
          ></v-date-picker>
        </v-menu>
      </v-btn-toggle>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="5"
      lg="5"
      class="py-0"
      :style="
        $vuetify.breakpoint.xsOnly
          ? 'display:flex; justify-content: end; align-items: right'
          : 'margin-right: -2%'
      "
    >
      <v-row
        :style="
          $vuetify.breakpoint.xsOnly
            ? 'display:flex; justify-content: center; align-items: center'
            : ''
        "
      >
        <v-col
          cols="4"
          style="min-width: 140px; max-width: 140px"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'padding-right: 8px; padding-left: 15px'
              : ''
          "
        >
          <v-menu
            v-model="menu.first_day"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="daymenu"
                outlined
                dense
                hide-details
                :value="$d(new Date(from), 'date2digits')"
                readonly
                v-bind="attrs"
                v-bind:class="{ 'error--text': from > to }"
                v-on="on"
              >
                <template v-slot:prepend-inner
                  ><v-icon small>$appointments</v-icon></template
                ></v-text-field
              >
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="from"
              color="primary"
              no-title
              @input="menu.first_day = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <span
          v-if="$vuetify.breakpoint.xsOnly"
          class="mt-n8"
          style="font-weight: bolder; font-size: 20px"
          >-
        </span>
        <span v-else class="mt-4">- </span>
        <v-col
          cols="4"
          style="min-width: 140px; max-width: 140px"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'padding-right: 15px; padding-left: 8px'
              : ''
          "
        >
          <v-menu
            v-model="menu.last_day"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="daymenu"
                outlined
                dense
                hide-details
                :value="$d(new Date(to), 'date2digits')"
                readonly
                v-bind="attrs"
                v-on="on"
                v-bind:class="{ 'error--text': from > to }"
              >
                <template v-slot:prepend-inner
                  ><v-icon small>$appointments</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="to"
              color="primary"
              :min="from"
              no-title
              @input="menu.last_day = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import * as moment from "moment";
export default {
  name: "MonthSelectorStats",
  data() {
    return {
      menu: {
        month: false,
        day: false,
        week: false,
      },
    };
  },
  mounted() {
    /*
    if (!this.day) this.day = moment().format("YYYY-MM-DD");
    else this.day = this.day;
    */
    this.month = moment().format("YYYY-MM");
  },
  computed: {
    ...mapState("stats", ["filters", "filter_type"]),
    btn: {
      get() {
        return this.filter_type;
      },
      set(value) {
        this.$store.commit("stats/SET_FILTER_TYPE", value);
        console.log(value);
      },
    },
    from: {
      get() {
        return this.filters.from;
      },
      set(value) {
        this.$store.commit("stats/SET_FROM", value);
        console.log(value);
      },
    },
    to: {
      get() {
        return this.filters.to;
      },
      set(value) {
        this.$store.commit("stats/SET_TO", value);
        console.log(value);
      },
    },
    month: {
      get() {
        let split = this.filters.from.split("-");
        return `${split[0]}-${split[1]}`;
      },
      set(value) {
        console.log(value);
        value = value.split("-");
        console.log(value);

        let from = new Date(value[0], value[1] - 1, 1);
        this.$store.commit(
          "stats/SET_FROM",
          `${from.getFullYear()}-${
            from.getMonth() + 1 >= 10
              ? +from.getMonth() + 1
              : "0" + (+from.getMonth() + 1)
          }-${from.getDate() >= 10 ? from.getDate() : "0" + from.getDate()}`
        );
        let to = new Date(value[0], value[1], 0);
        console.log(from);
        console.log(to);
        this.$store.commit(
          "stats/SET_TO",
          `${to.getFullYear()}-${
            to.getMonth() + 1 >= 10
              ? +to.getMonth() + 1
              : "0" + (+to.getMonth() + 1)
          }-${to.getDate() >= 10 ? to.getDate() : "0" + to.getDate()}`
        );
      },
    },
    week: {
      get() {
        return [this.filters.from, this.filters.to];
      },
      set(value) {
        value = value[0];
        value = new Date(value);
        let d = value.getDate() + 1;
        let di = value.getDay() === 0 ? 7 : value.getDay();
        value.setDate(d - di);
        this.$store.commit(
          "stats/SET_FROM",
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`
        );
        value.setDate(value.getDate() + 6);
        this.$store.commit(
          "stats/SET_TO",
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`
        );
      },
    },
    day: {
      get() {
        return this.filters.from;
      },
      set(value) {
        this.$store.commit("stats/SET_FROM", value);
        this.$store.commit("stats/SET_TO", value);
      },
    },
  },
};
</script>
